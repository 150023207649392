import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { PmCoreLocaleLocalizePathPipe } from '@pm/core/locale';
import { PmIconViberComponent } from '@pm/ui/icons';

import { PmHeaderMegamenuComponent } from './components/pm-header-megamenu/pm-header-megamenu.component';
import { HeaderComponent } from './components/pm-header/header.component';

@NgModule({
  imports: [
    CommonModule,
    MenubarModule,
    ButtonModule,
    DropdownModule,
    TranslocoModule,
    PmHeaderMegamenuComponent,
    FormsModule,
    PmCoreLocaleLocalizePathPipe,
    PmIconViberComponent
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class PmUiPmHeaderModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { PmCoreLocaleLocalizePathPipe } from '@pm/core/locale';
import { PmIconViberComponent } from '@pm/ui/icons';

import { PmUiPmFooterComponent } from './components/pm-ui-footer/pm-ui-footer.component';
import { PmUiMobileLinksComponent } from './components/pm-ui-mobile-links/pm-ui-mobile-links.component';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    PmUiMobileLinksComponent,
    PmCoreLocaleLocalizePathPipe,
    PmIconViberComponent
  ],
  declarations: [PmUiPmFooterComponent],
  exports: [PmUiPmFooterComponent],
})
export class PmUiPmFooterModule {}

import '@angular/common/locales/global/ar';
import '@angular/common/locales/global/cs';
import '@angular/common/locales/global/da';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/el';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/et';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/hi';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/lt';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/pt-PT';
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/sl';
import '@angular/common/locales/global/sv';
import '@angular/common/locales/global/tr';
import '@angular/common/locales/global/uk';
import '@angular/common/locales/global/zh';
import '@angular/common/locales/global/ru';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG } from '@pm/config';
import { APP_TYPE, AppType } from '@pm/core/utils';
import { APP_VERSION } from '@pm/core/version';
import * as Sentry from '@sentry/angular-ivy';

import packageJson from '../package.json';
import { AppModule } from './app/app.module';

/* eslint-disable @typescript-eslint/no-var-requires */
window.Buffer = window.Buffer || require('buffer').Buffer;

function bootstrap() {
  fetch('./config/config.json')
    .then((resp) => resp.json())
    .then((config) => {
      Sentry.init({
        release: [packageJson.name, packageJson.version].join('@'),
        dsn: config.dns,
        enabled: process.env['NODE_ENV'] === 'production',
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', /^https:\/\/ideabox\.name/],
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
          new RegExp(
            `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
            'mi',
          ),
        ],
      });
      platformBrowserDynamic([
        { provide: APP_CONFIG, useValue: config },
        { provide: APP_TYPE, useValue: AppType.landing },
        {
          provide: APP_VERSION,
          useValue: packageJson.version,
        },
      ])
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    });
}

if (document.readyState !== 'loading') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

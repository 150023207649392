<ng-container *transloco="let t; read: 'core'">
  <pm-ui-section-hero
    [title]="t('hero-default.title')"
    [subtitle]="t('hero-default.subtitle')"
    [image]="'assets/ui/default-hero-man.png'"
  />
  <div class="pm-core-page-default">
    <div class="pm-core-page-default__sections">
      <div
        #sections
        class="pm-core-page-default__section pm-core-page-default__section--centered"
      ></div>
    </div>
    <pm-feedback-form-section></pm-feedback-form-section>
    <pm-core-seo-text></pm-core-seo-text>
  </div>
</ng-container>

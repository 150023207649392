<div
  class="pm-header-megamenu"
  [ngClass]="{
    'pm-header-megamenu--light': theme === 'light',
    'pm-header-megamenu--dark': theme === 'dark'
  }"
>
  <p-megaMenu
    [model]="items()"
    orientation="horizontal"
    class="w-full hidden lg:flex"
  >
    <ng-template pTemplate="start">
      <a [routerLink]="['/' | localizepath]" class="mr-4">
        <pm-header-logo
          [size]="isSticked ? 'sm' : 'md'"
          [theme]="theme"
        ></pm-header-logo>
      </a>
    </ng-template>
    <ng-template pTemplate="item" let-processedItem>
      <a
        *ngIf="!getItemProp(processedItem, 'routerLink')"
        [attr.href]="getItemProp(processedItem, 'url')"
        [attr.data-automationid]="getItemProp(processedItem, 'automationId')"
        [attr.data-pc-section]="'action'"
        [target]="getItemProp(processedItem, 'target')"
        [ngClass]="{
          'p-menuitem-link': true,
          'p-disabled': getItemProp(processedItem, 'disabled')
        }"
        [attr.tabindex]="-1"
        pRipple
      >
        <span
          *ngIf="getItemProp(processedItem, 'icon')"
          class="p-menuitem-icon"
          [ngClass]="getItemProp(processedItem, 'icon')"
          [ngStyle]="getItemProp(processedItem, 'iconStyle')"
          [attr.data-pc-section]="'icon'"
          [attr.tabindex]="-1"
        >
        </span>
        <span
          *ngIf="getItemProp(processedItem, 'escape'); else htmlLabel"
          class="p-menuitem-text"
          [attr.data-pc-section]="'label'"
        >
          {{ getItemLabel(processedItem) }}
        </span>
        <ng-template #htmlLabel>
          <span
            class="p-menuitem-text"
            [innerHTML]="getItemLabel(processedItem)"
            [attr.data-pc-section]="'label'"
          ></span>
        </ng-template>
        <span
          class="p-menuitem-badge"
          *ngIf="getItemProp(processedItem, 'badge')"
          [ngClass]="getItemProp(processedItem, 'badgeStyleClass')"
          >{{ getItemProp(processedItem, 'badge') }}</span
        >

        <ng-container *ngIf="isItemGroup(processedItem)">
          <i class="pi pi-angle-down p-submenu-icon"></i>
        </ng-container>
      </a>
      <a
        *ngIf="getItemProp(processedItem, 'routerLink')"
        [routerLink]="getItemProp(processedItem, 'routerLink')"
        [attr.data-automationid]="getItemProp(processedItem, 'automationId')"
        [attr.tabindex]="-1"
        [attr.data-pc-section]="'action'"
        [queryParams]="getItemProp(processedItem, 'queryParams')"
        [routerLinkActive]="'p-menuitem-link-active'"
        [routerLinkActiveOptions]="
          getItemProp(processedItem, 'routerLinkActiveOptions') || {
            exact: false
          }
        "
        [target]="getItemProp(processedItem, 'target')"
        [ngClass]="{
          'p-menuitem-link': true,
          'p-disabled': getItemProp(processedItem, 'disabled')
        }"
        [fragment]="getItemProp(processedItem, 'fragment')"
        [queryParamsHandling]="
          getItemProp(processedItem, 'queryParamsHandling')
        "
        [preserveFragment]="getItemProp(processedItem, 'preserveFragment')"
        [skipLocationChange]="getItemProp(processedItem, 'skipLocationChange')"
        [replaceUrl]="getItemProp(processedItem, 'replaceUrl')"
        [state]="getItemProp(processedItem, 'state')"
        pRipple
      >
        <span
          class="p-menuitem-icon"
          *ngIf="getItemProp(processedItem, 'icon')"
          [ngClass]="getItemProp(processedItem, 'icon')"
          [ngStyle]="getItemProp(processedItem, 'iconStyle')"
          [attr.data-pc-section]="'icon'"
          [attr.tabindex]="-1"
        ></span>
        <span
          class="p-menuitem-text"
          *ngIf="getItemProp(processedItem, 'escape'); else htmlRouteLabel"
          >{{ getItemLabel(processedItem) }}</span
        >
        <ng-template #htmlRouteLabel
          ><span
            class="p-menuitem-text"
            [innerHTML]="getItemLabel(processedItem)"
            [attr.data-pc-section]="'label'"
          ></span
        ></ng-template>
        <span
          class="p-menuitem-badge"
          *ngIf="getItemProp(processedItem, 'badge')"
          [ngClass]="getItemProp(processedItem, 'badgeStyleClass')"
          >{{ getItemProp(processedItem, 'badge') }}</span
        >
        <ng-container *ngIf="isItemGroup(processedItem)">
          <i class="pi pi-angle-down p-submenu-icon"></i>
        </ng-container>
      </a>
    </ng-template>
    <ng-template pTemplate="end">
      @defer {
        @if (isAuthorized()) {
          <p-button
            styleClass="p-button-primary"
            [icon]="'pi pi-home'"
            [label]="'ui.dashboard' | transloco"
            (onClick)="goToDashboard()"
          ></p-button>
          <p-button
            [text]="true"
            icon="pi pi-sign-out"
            severity="secondary"
            (onClick)="signOut()"
          ></p-button>
        } @else {
          <p-button
            [label]="'ui.sign-in' | transloco"
            [icon]="'pi pi-sign-in'"
            [severity]="'primary'"
            [routerLink]="['/auth/signin' | localizepath]"
          ></p-button>
        }
      }
    </ng-template>
  </p-megaMenu>
  <p-menubar [model]="mobileMenu()" id="menubar" class="w-full flex lg:hidden">
    <ng-template pTemplate="start">
      <a [routerLink]="['/' | localizepath]">
        <pm-header-logo [size]="'sm'" [theme]="theme"></pm-header-logo>
      </a>
    </ng-template>
  </p-menubar>
</div>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PageText } from '@pm/core/utils';

@Injectable({
  providedIn: 'root',
})
export class PmCorePageTextService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
    @Inject(LOCALE_ID) public locale: string,
    private readonly _translate: TranslocoService
  ) {}

  getPageText$(pageUrl: string) {
    const pathOnly = pageUrl.split('?')[0];
    const currentLanguage = this._translate.getActiveLang();

    return this._http.get<PageText>(
      `${this.appConfig.api}/${currentLanguage ?? 'en'}/pages/${
        this.appConfig.domain
      }?_format=json&url=${pathOnly}`,
    );
  }

  getRegionalDomains$() {
    return this._http.get<{ [key: string]: string }>(
      `${this.appConfig.api}/api/v1/regional-domains`,
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PmCoreSeoMetatagsComponent } from './components/pm-core-seo-metatags/pm-core-seo-metatags.component';
import { PmCoreSeoTextComponent } from './components/pm-core-seo-text/pm-core-seo-text.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PmCoreSeoTextComponent, PmCoreSeoMetatagsComponent],
  exports: [PmCoreSeoTextComponent, PmCoreSeoMetatagsComponent],
})
export class PmCoreSeoModule {}

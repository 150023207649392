/* eslint-disable @typescript-eslint/no-explicit-any */
import { MegaMenuItem, MenuItem } from 'primeng/api';

import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmAuthService } from '@pm/auth/utils';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class PmHeaderMegamenuMapperService {
  isAuthorized = toSignal(this._authService.isLoggedIn$);

  constructor(
    private readonly _transloco: TranslocoService,
    @Inject(APP_CONFIG) private readonly _config: AppConfig,
    private readonly _authService: PmAuthService,
  ) {}

  getMainMenuItems(): MegaMenuItem[] {
    switch (this._config.routesFor) {
      case 'profitmarkua':
        return this._prependCountryCodeToRouterLinks(this._buildUaMenuItems());
      default:
        return this._buildMenuItems();
    }
  }

  getMobileMenuItems(): MenuItem[] {
    switch (this._config.routesFor) {
      case 'profitmarkua':
        return this._buildMobileMenuItems();
      default:
        return this._buildMobileMenuItems();
    }
  }

  goToDashboard() {
    this._authService.openDashboard();
  }

  signOut() {
    this._authService.signOut();
  }

  private _buildMenuItems = () => {
    const trademarkRegistrationMenuitem = this._mapTrademarkMenuItems();
    return [
      {
        label: this._transloco.translate('header.menu.services'),
        items: [
          [trademarkRegistrationMenuitem],
          [
            {
              label: this._transloco.translate(
                'header.menu.patent-registration',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.copy-right-registration-usa',
                  ),
                  routerLink: '/copyright-us',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.design-registration-eu',
                  ),
                  routerLink: '/design-eu',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.invention-registration',
                  ),
                  routerLink: '/invention',
                },
              ],
            },
          ],
          [
            {
              label: this._transloco.translate(
                'header.menu.additional-services',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.domains-registration',
                  ),
                  routerLink: '/domains',
                },
              ],
            },
          ],
        ],
      },
      {
        label: this._transloco.translate('header.menu.our-clients'),
        routerLink: '/clients',
      },
      {
        label: this._transloco.translate('header.menu.about-us'),
        routerLink: '/about',
      },
    ];
  };

  private _buildUaMenuItems = () => {
    const trademarkRegistrationMenuitem = this._mapTrademarkMenuItems();
    return [
      {
        label: this._transloco.translate('header.menu.services'),
        items: [
          [trademarkRegistrationMenuitem],
          [
            {
              label: this._transloco.translate(
                'header.menu.other-patent-objects',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.ua-copyright-registration',
                  ),
                  routerLink: '/copyright',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.us-copyright-registration',
                  ),
                  routerLink: '/copyright-us',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.ua-design-registration',
                  ),
                  routerLink: '/patent_na_promishleniy_obrazec',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.invention-registration',
                  ),
                  routerLink: '/patent_na_izobretenie',
                },
              ],
            },
          ],
          [
            {
              label: this._transloco.translate(
                'header.menu.additional-services',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.domains-registration',
                  ),
                  routerLink: '/registratsyia_domenov',
                },
              ],
            },
          ],
        ],
      },
      {
        label: this._transloco.translate('header.menu.our-clients'),
        routerLink: '/nashi_clienti',
      },
      {
        label: this._transloco.translate('header.menu.about-us'),
        routerLink: '/patentnyi_poverenniy',
      },
    ];
  };

  private _buildMobileMenuItems = () => {
    const mobileOnly = [
      {
        label: this._transloco.translate('header.menu.blog'),
        routerLink: '/blog',
      },
      ...(this.isAuthorized()
        ? [
            {
              label: `<div class="flex gap-2 align-items-center"><div class="w-2rem px-1"><i class="pi pi-home"></i></div><div>${this._transloco.translate(
                'header.menu.dashboard',
              )}</div></div>`,
              command: () => this.goToDashboard(),
              escape: false,
              styleClass: "bg-orange-500"
            },
            {
              label: this._transloco.translate('header.menu.signout'),
              command: () => this.signOut(),
            },
          ]
        : [
            {
              label: `<div class="flex gap-2 align-items-center"><div class="w-2rem px-1"><i class="pi pi-user"></i></div><div>${this._transloco.translate(
                'header.menu.signin',
              )}</div></div>`,
              routerLink: '/auth/signin',
              escape: false,
              styleClass: "bg-orange-500"
            },
          ]),
    ];
    return [
      ...this._mapMenuItems(this.getMainMenuItems()),
      ...this._prependCountryCodeToRouterLinks(mobileOnly),
    ];
  };

  private _mapMenuItems = (items: MegaMenuItem[]) =>
    items.map((item): MenuItem => {
      if (item.items) {
        return {
          ...item,
          items: item.items.flat(),
        };
      }

      return {
        ...item,
      };
    });

  private _mapTrademarkMenuItems() {
    const inEuLabel = this._transloco.translate('ui.trademark-in-eu');
    const countryCode = this._config.mainCountry || '';
    const labelText = this._transloco.translate('ui.trademark-in', {
      country: countryCode.toUpperCase(),
    });

    const createMenuItem = (
      label: string,
      routerLink: string,
      flagCode: string,
    ) => ({
      label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${flagCode} border-round"></div><div>${label}</div></div>`,
      escape: false,
      routerLink,
    });

    const currentCountryMenuItem = createMenuItem(
      labelText,
      '/',
      countryCode.toLowerCase(),
    );
    const trademarksLinks = [currentCountryMenuItem];

    if (this._config.isEuPageEnabled) {
      trademarksLinks.push(createMenuItem(inEuLabel, '/eu', 'eu'));
    }

    if (countryCode.toUpperCase() === 'UA') {
      const internationLabel = this._transloco.translate(
        'header.menu.madrid-trademark-registration',
      );
      trademarksLinks.push({
        label: `<div class="flex gap-2 align-items-center"><div class="w-2rem px-1"><i class="pi pi-globe"></i></div><div>${internationLabel}</div></div>`,
        escape: false,
        routerLink: '/mezhdunarodnaya-registratsiya_torgovoy_marki',
      });
    }

    if (this._config.mainCountry?.toUpperCase() === 'EU') {
      const localWebsites = ['es', 'pl', 'pt'];
      const euMenuItem = createMenuItem(inEuLabel, '/', 'eu');

      const items = localWebsites.map((code) => {
        const localLabelText = this._transloco.translate('ui.trademark-in', {
          country: code.toUpperCase(),
        });
        return {
          label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${code} border-round"></div><div>${localLabelText}</div></div>`,
          escape: false,
          url: `https://profitmark.${code}`,
        };
      });

      return {
        label: this._transloco.translate('header.menu.trademark-registration'),
        items: [euMenuItem, ...items],
      };
    }
    return {
      label: this._transloco.translate('header.menu.trademark-registration'),
      items: [...trademarksLinks],
    };
  }

  private _prependCountryCodeToRouterLinks(
    menuItems: MegaMenuItem[],
  ): MegaMenuItem[] {
    const currentLanguage = this._transloco.getActiveLang();
    const defaultLanguage = this._config.localeCode;
    const isDefaultLanguage = currentLanguage === defaultLanguage;

    if (isDefaultLanguage) {
      return menuItems;
    }
    return menuItems.map((item) => {
      const updateRouterLink = (link: string) => `/${currentLanguage}${link}`;

      const updateSubItems = (subItems: MenuItem[]): any =>
        subItems.map((subItem) => ({
          ...subItem,
          routerLink: subItem.routerLink
            ? updateRouterLink(subItem.routerLink)
            : subItem.routerLink,
          items: subItem.items ? updateSubItems(subItem.items) : subItem.items,
        }));

      return {
        ...item,
        routerLink: item.routerLink
          ? updateRouterLink(item.routerLink)
          : item.routerLink,
        items: item.items ? item.items.map(updateSubItems) : item.items,
      };
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { FeedbackForm } from '@pm/feedback/utils';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PmFeedbackService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
    private _recaptcha: ReCaptchaV3Service
  ) {}

  submitFeedbackForm$(data: FeedbackForm) {
    return this._recaptcha.execute('feedback').pipe(
      switchMap((token) => {
        return this._http.post(
          `${this.appConfig.api}/api/v1/feedback_form?_format=json`,
          { ...data, token }
        );
      })
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'pm-ui-section-hero',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './pm-ui-section-hero.component.html',
  styleUrls: ['./pm-ui-section-hero.component.scss'],
})
export class PmUiSectionHeroComponent {
  @Input() country!: string | undefined;
  @Input() title!: string | undefined;
  @Input() subtitle!: string | undefined;
  @Input() steps!: string[] | undefined;
  @Input() cta!: string | undefined;
  @Input() image!: string | undefined;
}

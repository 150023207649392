import { Route } from '@angular/router';
import {
  PmCorePageDefaultComponent,
  PmCorePageNotFoundComponent,
} from '@pm/core/page';
import { pmCoreSeoMetatagsGuard } from '@pm/core/seo';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivateChild: [pmCoreSeoMetatagsGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@pm/home/page').then((m) => m.PmHomePageModule),
        data: { state: 'dark' },
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('@pm/auth/page').then((m) => m.PmAuthPageModule),
      },
      {
        path: 'about',
        loadChildren: () =>
          import('@pm/about/page').then((m) => m.PmAboutPageModule),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('@pm/clients/page').then((m) => m.PmClientsPageModule),
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('@pm/terms/page').then((m) => m.PmTermsPageModule),
      },
      {
        path: 'domains',
        loadChildren: () =>
          import('@pm/domains/page').then((m) => m.PmDomainsPageModule),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('@pm/blog/page').then((m) => m.PmBlogPageModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('@pm/ip/page').then((m) => m.pmIpPageSpecialRoutes),
        data: { state: 'dark' },
      },
    ],
  },
  {
    path: '**',
    component: PmCorePageNotFoundComponent,
  },
];

export const uaAppRoutes: Route[] = [
  {
    path: '',
    canActivateChild: [pmCoreSeoMetatagsGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@pm/home/page').then((m) => m.PmHomePageModule),
        data: { state: 'dark' },
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('@pm/auth/page').then((m) => m.PmAuthPageModule),
        data: {
          meta: {
            robots: 'noindex, noarchive',
          },
        },
      },
      {
        path: 'patentnyi_poverenniy',
        loadChildren: () =>
          import('@pm/about/page').then((m) => m.PmAboutPageModule),
      },
      {
        path: 'nashi_clienti',
        loadChildren: () =>
          import('@pm/clients/page').then((m) => m.PmClientsPageModule),
      },
      {
        path: 'agreement',
        loadChildren: () =>
          import('@pm/terms/page').then((m) => m.PmTermsPageModule),
      },
      {
        path: 'registratsyia_domenov',
        loadChildren: () =>
          import('@pm/domains/page').then((m) => m.PmDomainsPageModule),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('@pm/blog/page').then((m) => m.PmBlogPageModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('@pm/ip/page').then((m) => m.pmIpPageSpecialRoutesUa),
        data: { state: 'dark' },
      },
      {
        path: 'partnerskaya-programma',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      // Applications routes
      {
        path: 'online_trademark_registration',
        data: {
          meta: {
            robots: 'noindex, noarchive',
          },
          state: 'dark',
        },
        loadChildren: () =>
          import('@pm/application/page').then((m) => m.pmApplicationPageRoutes),
      },
      {
        path: 'online_tm_registration',
        data: {
          country: 'us',
          meta: {
            robots: 'noindex, noarchive',
          },
          state: 'dark',
        },
        loadComponent: () =>
          import('@pm/application/page').then(
            (m) => m.PmApplicationPageNationalComponent,
          ),
      },
      {
        path: 'mezhdunarodnaya-registratsiya_torgovoy_marki',
        data: { type: 'madrid', state: 'dark' },
        loadComponent: () =>
          import('@pm/application/page').then(
            (m) => m.PmApplicationPageMadridComponent,
          ),
      },
      {
        path: 'eu',
        loadComponent: () =>
          import('@pm/home/page').then((m) => m.PmHomePageEuComponent),
        data: { state: 'dark' },
      },
      // Other routes
      {
        path: 'reviews',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'registraciya-torgovoy-marki-dlya-domena-ua',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'gift-certificate',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'razrabotka-logotipa',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'razrabotka-firmennogo-stylia',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'razrabotka-brendbuka',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'design/wizard',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'neyming',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'faq/kak-proverit-torgovuyu-marku-na-noviznu',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'content/umovi-nabuttya-pravovoyi-ohoroni-torgovelnoyi-marki',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'content/shcho-take-torgovelna-marka',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'hosting',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'profitmark-pro',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'vozrazhenie-protiv-tm',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'peredacha-prav',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'trademark-monitoring',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'sell-tm',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'shark-chat',
        component: PmCorePageDefaultComponent,
        data: {
          state: 'dark',
        },
      },
      {
        path: 'terms',
        redirectTo: 'agreement',
      },
      {
        path: 'clients',
        redirectTo: 'nashi_clienti',
      },
      {
        path: '404',
        component: PmCorePageNotFoundComponent,
      }
    ],
  },
];

import { inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanActivateFn } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

export const pmCoreSeoMetatagsGuard: CanActivateFn = (route) => {
  const metatags = inject(Meta);
  const title = inject(Title);
  const translate = inject(TranslocoService);

  const meta = route.data['meta'];
  const pageTitle = meta?.['title'];
  const pageDescription = meta?.['description'];
  const robots = meta?.['robots'];

  if (pageTitle) {
    translate
      .selectTranslate(pageTitle)
      .subscribe((translatedTitle) => title.setTitle(translatedTitle));
  }

  if (pageDescription) {
    translate
      .selectTranslate(pageDescription)
      .subscribe((translatedDescription) =>
        metatags.updateTag({
          name: 'description',
          content: translatedDescription,
        }),
      );
  }

  if (robots) {
    metatags.updateTag({
      name: 'robots',
      content: robots,
    });
  }

  return true;
};

<ng-container *transloco="let t; read: 'ui'">
  <div class="pm-ui-sectiom-hero">
    <div class="pm-ui-sectiom-hero--gradient">
      <div class="pm-ui-sectiom-hero__content">
        <div class="pm-ui-sectiom-hero__content__text">
          <div class="pm-ui-sectiom-hero__content__text__stepper">
            @for (item of steps; track $index) {
              <div class="pm-ui-sectiom-hero__content__text__stepper--step">
                {{ $index + 1 }} {{ item }}
              </div>
            }
          </div>
          <div class="pm-ui-sectiom-hero__content__text__title">
            <div class="pb-3">
              @if (title) {
                <span class="text-6xl md:text-7xl font-bold">{{ title }}</span>
              }
            </div>
            @if (subtitle) {
              <small class="text-lg line-height-3">{{ subtitle }}</small>
            }
          </div>
        </div>
        <div
          class="pm-ui-sectiom-hero__content__image"
          [ngStyle]="{ 'background-image': 'url(' + image + ')' }"
        ></div>
      </div>
    </div>
  </div>
</ng-container>

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Pipe({
  name: 'localizepath',
  standalone: true,
  pure: false
})
export class PmCoreLocaleLocalizePathPipe implements PipeTransform {
  constructor(
    private readonly _transloco: TranslocoService,
    @Inject(APP_CONFIG) private readonly _config: AppConfig,
  ) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const currentLanguage = this._transloco.getActiveLang();
    const defaultLanguage = this._config.localeCode;
    const isDefaultLanguage = currentLanguage === defaultLanguage;

    if (isDefaultLanguage) {
      return value;
    }

    if (typeof value === 'string') {
      return `/${currentLanguage}${value}`;
    }

    return null;
  }
}

import { take } from 'rxjs';

import { FeedbackForm } from '@pm/feedback/utils';

import { PmFeedbackService } from '../services/pm-feedback.service';

export class PmFeedbackBloc {
  constructor(private _feedback: PmFeedbackService) {}

  submitFeedbackForm$(data: FeedbackForm) {
    return this._feedback.submitFeedbackForm$(data).pipe(take(1));
  }
}

import { isPlatformBrowser, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Injectable({
  providedIn: 'root',
})
export class PmCoreLocaleRedirectService {
  private readonly LOCALE_KEY = 'locale';

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(APP_CONFIG) private readonly config: AppConfig,
  ) {}

  redirectOnInitialLoad(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const enabledLanguages = this.config.enabledLanguages;
    if (!enabledLanguages) {
      return;
    }

    const locale = this.getLocaleSelected() || 'uk';
    const path = this.location.path();
    const [url, params] = path.split('?');
    const navigateTo = this._getPathSegments(url, enabledLanguages);

    if (this._shouldRedirect(navigateTo, locale, enabledLanguages)) {
      this.router.navigate(
        this._getRedirectPathSegments(navigateTo, locale, enabledLanguages),
        { queryParams: this._getRedirectQueryParams(params) },
      );
    }
  }

  setLocaleSelected(locale: string): void {
    this._setToStore(this.LOCALE_KEY, locale);
  }

  getLocaleSelected(): string {
    return this._getFromStore(this.LOCALE_KEY);
  }

  navigateWithLocale(path: string, locale: string): void {
    const { enabledLanguages, localeCode: defaultLanguage } = this.config;
    if (!enabledLanguages) {
      this.router.navigate([path]);
      return;
    }

    const [url, params] = path.split('?');
    const navigateTo = this._getPathSegmentsWithoutLocale(
      url,
      enabledLanguages,
    );

    const targetPath =
      locale === defaultLanguage
        ? navigateTo.length
          ? navigateTo
          : ['/']
        : [locale, ...navigateTo];

    this.router.navigate(targetPath, {
      queryParams: this._getRedirectQueryParams(params),
    });
  }

  private _getRedirectQueryParams(
    params?: string,
  ): Record<string, string> | undefined {
    return params
      ?.split('&')
      .map((param) => param.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  private _getFromStore(key: string): string {
    return localStorage.getItem(key) || '';
  }

  private _setToStore(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private _getPathSegments(
    path: string,
    enabledLanguages?: string[],
  ): string[] {
    const [url, params] = path.split('?');

    return url
      .split('/')
      .filter(Boolean)
      .filter((segment) => segment !== '/');
  }

  private _shouldRedirect(
    navigateTo: string[],
    locale: string,
    enabledLanguages: string[],
  ): boolean {
    const isLocalizedRoute = navigateTo.some((segment) =>
      enabledLanguages.includes(segment),
    );
    const isSameLocale = locale === this.config.localeCode;

    return !(isLocalizedRoute || isSameLocale);
  }

  private _getRedirectPathSegments(
    navigateTo: string[],
    locale: string,
    enabledLanguages: string[],
  ): string[] {
    return [
      locale,
      ...navigateTo.filter((segment) => !enabledLanguages.includes(segment)),
    ];
  }

  private _getPathSegmentsWithoutLocale(
    path: string,
    enabledLanguages: string[],
  ): string[] {
    return this._getPathSegments(path).filter(
      (segment) => !enabledLanguages.includes(segment),
    );
  }
}
